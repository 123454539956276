import React from "react"
import {Card, CardContent} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {Link} from "./link";
import {cardStyle, linkStyle, descriptionStyle, titleStyle} from "./linkCard.module.scss";

const LinkCard = (item: Link, index: number) => {
    return (
        <Grid xs={2} key={index}>
            <Card className={cardStyle}>
                <a href={`${item.url}`} target="_blank"  className={linkStyle}>
                    <CardContent>
                        <h5 className={titleStyle}>{item.text}</h5>
                        <p className={descriptionStyle}>{item.description}</p>
                    </CardContent>
                </a>
            </Card>
        </Grid>
    )
}

export default LinkCard