import * as React from "react"
import Grid from '@mui/material/Unstable_Grid2';
import data from "../data/links.json";
import {
    headingAccentStyles,
    headingStyles,
    pageStyles
} from "../styles/style";
import logo from "../images/logo.png";
import {Link} from "../components/link";
import LinkCard from "../components/linkCard";
import {Divider} from "@mui/material";


// markup
const IndexPage = () => {
    const links: Link[] = data;

    const qframeAdministratie = links.filter(l => l.from == "Qframe" && l.category == "Administratie");
    const qframeDev = links.filter(l => l.from == "Qframe" && l.category == "Development");
    const qframeConfluence = links.filter(l => l.from == "Qframe" && l.category == "Confluence");

    const cronos = links.filter(l => l.from == "Cronos");

    return (
        <main style={pageStyles}>
            <title>Wegwijs @ Qframe</title>

            <Grid container spacing={20}>
                <Grid>
                    <img src={logo} height='100px'/>
                </Grid>
                <Grid>
                    <h1 style={headingStyles}>
                        Wegwijs @ Qframe
                        <br/>
                        <span style={headingAccentStyles}>Een aantal interessante links binnen Qframe </span>
                    </h1>
                </Grid>
            </Grid>

            <div>
                <h3>Qframe Administratie</h3>
                <Grid container spacing={2} columns={{ xs: 1, sm: 4, md: 6, lg: 10 }}>
                    {qframeAdministratie.map((link, index) => (
                        LinkCard(link, index)
                    ))}
                </Grid>
            </div>
            <Divider orientation={"horizontal"} style={{"margin-top": "20px"}} />
            <div>
                <h3>Qframe Development</h3>
                <Grid container spacing={2} columns={{ xs: 1, sm: 4, md: 6, lg: 10 }}>
                    {qframeDev.map((link, index) => (
                        LinkCard(link, index)
                    ))}
                </Grid>
            </div>
            <Divider orientation={"horizontal"} style={{"margin-top": "20px"}} />
            <div>
                <h3>Qframe Confluence</h3>
                <Grid container spacing={2} columns={{ xs: 1, sm: 4, md: 6, lg: 10 }}>
                    {qframeConfluence.map((link, index) => (
                        LinkCard(link, index)
                    ))}
                </Grid>
            </div>
            <Divider orientation={"horizontal"} style={{"margin-top": "20px"}} />
            <div>
                <h3>Cronos</h3>
                <Grid container spacing={2} columns={{ xs: 1, sm: 4, md: 6, lg: 10 }}>
                    {cronos.map((link, index) => (
                        LinkCard(link, index)
                    ))}
                </Grid>
            </div>
        </main>
    )
}

export default IndexPage
