// styles
export const pageStyles = {
    color: "#232129",
    paddingLeft: 96,
    paddingRight: 96,
    paddingTop: 45,
    paddingBottom: 45,
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
export const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 1024,

}
export const headingAccentStyles = {
    color: "#bbd150",
    fontSize: "20px"
}
